import React from "react"
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Title from "../title"
import { useIntl } from "gatsby-plugin-react-intl"

import * as styles from "../../styles/components/Resources/resources.module.scss"

// Vaccines and Office Policies
const resourceFiles = [
  {
    title: "Kids & Teens Medical Group Vaccine Schedule",
    url: "vaccine-schedule.pdf",
  },
  {
    title: "Sports Physical Policy Letter Document",
    url: "sports.pdf",
  },
]

// Development Milestones
const resourceFiles2 = [
  {
    title: "Developmental Milestones - 02 Months ",
    url: "2-months.pdf",
  },
  {
    title: "Developmental Milestones - 04 Months",
    url: "4-months.pdf",
  },
  {
    title: "Developmental Milestones - 06 Months",
    url: "6-months.pdf",
  },
  {
    title: "Developmental Milestones - 12 Months (1 Year)",
    url: "12-months.pdf",
  },
  {
    title: "Developmental Milestones - 18 Months",
    url: "12-months.pdf",
  },
  {
    title: "Developmental Milestones - 24 Months (2 Years)",
    url: "24-months.pdf",
  },
]

export default function Resources() {
  const intl = useIntl()
  return (
    <div>
      <Title title={intl.formatMessage({ id: "Resources" })} />
      <Container fluid>
        <Row xs={1} md={2}>
          <Col className={styles.wrapper}>
            <div className={`${styles.content}  mx-auto p-5`}>
              <h1 className={styles.title}>
                {intl.formatMessage({ id: "ParentResources" })}
              </h1>
              <p className={`${styles.text} my-4`}>
                {intl.formatMessage({ id: "Resources1" })}
              </p>
            </div>
          </Col>

          <Col className="p-0">
            <StaticImage
              src="../../images/resources/resources.jpg"
              layout="full_width"
              className={styles.img}
              alt=""
            />
          </Col>
        </Row>
      </Container>

      <Container className="my-5 py-5">
        <Row xs={1} md={2} className="g-5">
          {resourceFiles.map((file, i) => (
            <Col>
              <Card className={`${styles.card} mx-auto`}>
                <Card.Header className={`${styles.cardHeader}`}>
                  {file.title}
                </Card.Header>
                <Card.Body className={`${styles.cardBody}`}>
                  <iframe
                    src={`/resources/${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                    width="100%"
                    height="100%"
                  />
                </Card.Body>
                <Card.Footer className={styles.footer}>
                  <a
                    href={`/resources/${file.url}`}
                    className={styles.btn}
                    target="_blank"
                  >
                    <Button variant="secondary">
                      {intl.formatMessage({ id: "download" })}
                    </Button>
                  </a>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>

        <hr />

        <h1 className={`${styles.title2} mb-5 text-center`}>
          Developmental Milestones
        </h1>
        <Row xs={1} md={2} className="g-5">
          {resourceFiles2.map((file, i) => (
            <Col>
              <Card className={`${styles.card} mx-auto`}>
                <Card.Header className={`${styles.cardHeader}`}>
                  {file.title}
                </Card.Header>
                <Card.Body className={`${styles.cardBody}`}>
                  <iframe
                    src={`/resources/${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                    width="100%"
                    height="100%"
                  />
                </Card.Body>
                <Card.Footer className={styles.footer}>
                  <a
                    href={`/resources/${file.url}`}
                    className={styles.btn}
                    target="_blank"
                  >
                    <Button variant="secondary">Download</Button>
                  </a>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
