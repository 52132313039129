import * as React from "react"
import Layout from "../components/layout"
import Resources from "../components/Resources/resources"
import Seo from "../components/seo"

const PatientCare = () => (
    <Layout>
        <Seo title="Parent Resources" description="Check in for your virtual visit with Kids &amp; Teens Medical Group. Pediatrics serving patients in the Greater Los Angeles area . Call our office at 818-244-9595 or schedule an appointment online."/>
        <Resources />
    </Layout>
)

export default PatientCare
