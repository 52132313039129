// extracted by mini-css-extract-plugin
export var btn = "resources-module--btn--XwyKl";
export var card = "resources-module--card--qb7RS";
export var cardBody = "resources-module--cardBody--jyfbg";
export var cardHeader = "resources-module--cardHeader--KChvI";
export var content = "resources-module--content--Ef0gU";
export var content2 = "resources-module--content2--vv8sq";
export var footer = "resources-module--footer--S4-aI";
export var img = "resources-module--img--NeJus";
export var title = "resources-module--title--7kWc7";
export var wrapper = "resources-module--wrapper--h0gih";